import './info-num-box.css'

function InfoBox({ title, infoNum}) {
    return ( 
        <div className="info-box">
            <div className="info title">
                <h2> {title} </h2>
            </div>

            <div className="info-stuff">
                <p> {infoNum} </p>
            </div>

        </div>
     );
}

export default InfoBox;