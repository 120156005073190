import './nav.css'
import SideNavCard from './side-nav-card/side-nav-card';
import sad from '../../Assets/CM.png'
import sad1 from '../../Assets/bg-stuff-1.png'
import sad2 from '../../Assets/Nara_Clan_Symbol.png'


const Navbar = () => {
    return ( 

        <nav className="navbar">
            <div className="links">
                <SideNavCard img={sad1} to={"/"} title={'welcome'} game={'The welcome page'}/>
                <SideNavCard img={sad} to={"/covid-page"} title={'COVID-19 Data'} game={'COVID Data [WIP]'}/> 
                <SideNavCard img={sad2} to={"/anime"} title={'Amine List'} game={'Coming Soon'}/>



            </div>

        </nav>

     );
}
 
export default Navbar;