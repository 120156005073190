import './side-nav-card.css'
import { Link } from 'react-router-dom';


function SideNavCard({ to, title, game , img}) {
    return ( 
        <div className="side-nav-card">
            <Link to={to}>
                <div className="card-avatar">
                    <img className="card-img" src={img} />
                </div>

                <div className="card-info">
                    <div className='card-metadata'>
                        <div className='card-title'>
                            <p>{title}</p>
                        </div>
                        <div className='card-game-title'>
                            <p>{game}</p>
                        </div>
                    </div>
                    <div className='card-status'>
                        <p>200</p>
                    </div>
                </div>

            </Link>

        </div>
     );
}

export default SideNavCard;