
import './home-page.css'


function Home() {
    return (

      <div className="welcome">
        <div className="welcome-box">
          <div className="welcome-title">
            <h1> welcome </h1>  
          </div>

          {/* <div className="welcome-text">
            <h2> hi yes </h2>  
          </div> */}

        </div>

        </div>
    );
  }
  
  export default Home;