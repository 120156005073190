import Dropdown from "../../component/dropdown/dropdown-menu";
import DorpdownItem from "../../component/dropdown/dropdown-item";
import { useEffect, useState } from "react";
import InfoBox from "../../component/info-box/info-num-box/info-num-box";






function CovidPage() {

    const [countrise, setCountrise] = useState([]);
    const [country1, setCountry] = useState('worldwide');
    const [countryInfo, setCountryInfo] = useState({});

   
    useEffect(() => {
        const getCountriseData = async () => {
            await fetch("https://disease.sh/v3/covid-19/countries")
            .then((res) => res.json())
            .then((data) => {
                const countrise = data.map((country) => (
                    {
                        name: country.country,
                        value: country.countryInfo.iso2,


                    }
                ))
                setCountrise(countrise);
            })
        }
        getCountriseData()
    }, []);



    useEffect(() => {
        fetch("https://disease.sh/v3/covid-19/all")
        .then(resp => resp.json())
        .then(data => {
            setCountryInfo(data)
        })

    }, [])

    const onCountryChenge = async (event) => {
        const countryCode = event.target.value;

        
        
        

        const url = 
        countryCode === "worldwide"
            ? "https://disease.sh/v3/covid-19/all"
            : `https://disease.sh/v3/covid-19/countries/${countryCode}`

        await fetch(url) 
        .then((res) => res.json())
        .then((data) => {
            setCountry(countryCode)
            setCountryInfo(data);
        })

        console.log(countryCode)


    };

    

   

    

    console.log(countryInfo)

    return ( 
        <div>
            <form>
            <Dropdown value={country1} onChange={onCountryChenge}>    
            <DorpdownItem value="worldwide" key="worldwide" text="worldwide"/>
            {
                countrise.map((country) => (
                    <DorpdownItem value={country.value} text={country.name}/>
                ))   
            }
            </Dropdown>
            </form>


            <InfoBox title={"Population"} infoNum={countryInfo.population}/>
            <InfoBox title={"Cases"} infoNum={countryInfo.cases}/>
            <InfoBox title={"Deaths"} infoNum={countryInfo.deaths}/>
            <InfoBox title={"Tests"} infoNum={countryInfo.tests}/>
            <InfoBox title={"Today Cases"} infoNum={countryInfo.todayCases}/>
            <InfoBox title={"Active Cases"} infoNum={countryInfo.active}/>
            
        </div>
     );
}

export default CovidPage;