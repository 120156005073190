import './dropdown.css'



function DorpdownItem({text, id, value ,onChange}) {
    return ( 
        <option value={value} id={id} className="meun-item">
            {text}
        </option>
     );
}

export default DorpdownItem;