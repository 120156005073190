import './App.css';
import Navbar from '../nav/Nav';
import Home from '../../Pages/home-page/home'
import CovidPage from '../../Pages/covid-page/covid-page'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';




function App() {
  return (
    <Router>
      <div className="App">
        <div className='App-Nav'>
        <Navbar/>
        </div>
        <div className='App-main'>
          <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/covid-page' element={<CovidPage/>}/>

          </Routes>
          
        </div>

      </div>
      </Router>
  );
}




export default App;
