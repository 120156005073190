import './dropdown.css'



function Dropdown (props) {




    return ( 

        <div className="dropdown-div">
            <select value={props.text} onChange={props.onChange} className="dropdown ">               
                {props.children}
            </select>
        </div>


     );
}

export default Dropdown ;